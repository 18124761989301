import styled from 'styled-components';
import { darken } from 'polished';
import mountainsBg from '../images/mountains-background.svg';
import darkPatternBg from '../images/dark-section-pattern-logo-symbol.svg';
import beskydyBg from '../images/beskydy-background.svg';
import wood from '../images/forest.jpg';

const darkBackgroundLinearGradient = props => `linear-gradient(to top, ${darken(0.05, props.theme.colors.text)}, ${props.theme.colors.text})`;
const accentBackgroundLinearGradient = props => `linear-gradient(to bottom, ${darken(0.05, props.theme.colors.accent)}, ${props.theme.colors.accent})`;

export const Section = styled.section`
  padding: 3rem 0;
`;

const StyledSections = styled(Section)`
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: contain;
`;

export const AccentSection = styled(StyledSections)`
  background-color: ${props => props.theme.colors.accent};
  background-image: ${props => (props.withBackground ? `url(${mountainsBg}), ${accentBackgroundLinearGradient(props)}` : accentBackgroundLinearGradient(props))};
`;

export const DarkSection = styled(StyledSections)`
  background-color: ${props => props.theme.colors.text};
  background-image: ${props => (props.withBackground ? `url(${darkPatternBg}), ${darkBackgroundLinearGradient(props)}` : darkBackgroundLinearGradient(props))};
  background-position: 10%;
  background-repeat: repeat;
  background-size: auto;
`;

export const SectionTitle = styled.p`
  color: ${props => (props.white ? props.theme.colors.white : 'inherit')};
  font-size: ${props => (props.large ? '2rem' : '1.5rem')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

export const StyledBackgroundImage = styled.section`
  position: relative;
  padding: 0 0 3rem 0;
  background-image: url(${props => (props.backgroundImage ? props.backgroundImage : beskydyBg)});
  background-position: center 200px;
  background-repeat: repeat-x;
  background-size: cover;
`;

export const WoodSection = styled(Section)`
  background-image: url(${wood});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`;
