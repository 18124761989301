import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import Link from './Link';

const StyledTags = styled.div`
  margin-bottom: 1rem;
`;

const StyledTag = styled(Link)`
  margin: ${props => (props.large ? '0 4px 4px 0' : '0 2px 2px 0')};
  padding: ${props => (props.large ? '0.25rem 0.5rem' : '0.1rem 0.25rem')};
  border-radius: 2px;
  font-size: ${props => (props.large ? '1rem' : '14px')};
  background-color: ${props => lighten(0.35, props.theme.colors.accent)};
  text-decoration: none;
  text-transform: uppercase;
  transition: all 200ms;

  &:focus,
  &:hover {
    background-color: transparent;
  }
`;

const PostTags = props => (
  <StyledTags>
    {props.tags && props.tags.map(tag => tag.count > 0
      && (
        <StyledTag large={props.largeTags || false} key={tag.id} to={`/zazitky/${tag.slug}`}>{tag.name}</StyledTag>
      ))}
  </StyledTags>
);

export default PostTags;
