import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Box, Flex } from 'rebass';

const Image = ({ children, ...props }) => (
  <Box
    {...props}
  >
    {children}
  </Box>
);

const PostGallery = ({ images }) => {
  const views = [];
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  images.forEach((image) => {
    const view = {
      source: {
        regular: image.localFile.childImageSharp.fluid.src,
        thumbnail: image.localFile.childImageSharp.resize.src,
      },
    };
    views.push(view);
  });

  return (
    <>
      <Flex flexWrap="wrap">
        {images && images.map((image, j) => (
          <Box sx={{
            width: images.length > 1 ? '32%' : '100%', mr: 2, mb: 2, cursor: 'pointer',
          }}
          >
            <Image onClick={() => { setModalIsOpen(true); setCurrentIndex(j); }}>
              <img style={{ maxWidth: '100%' }} src={image.localFile.childImageSharp.resize.src} alt="" />
            </Image>
          </Box>
        ))}
      </Flex>

      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={() => setModalIsOpen(false)}>
            <Carousel
              views={views}
              currentIndex={currentIndex}
              components={{
                Footer: null,
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

export default PostGallery;
