import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Box } from 'rebass/styled-components';

import PostTags from './PostTags';
import PrimaryLink from './Buttons/PrimaryLink';
import PostGallery from './PostGallery';

const BlogPostTemplate = ({
  content,
  title,
  date,
  image,
  imageAlt,
  tags,
  show_booking_button: showBookingButton,
  gallery,
}) => (
  <article>
    {image && <Img fluid={image} alt={imageAlt} />}
    <h1 dangerouslySetInnerHTML={{ __html: title }} />
    <PostTags tags={tags} />
    <p>
      <small>{date}</small>
    </p>
    <div dangerouslySetInnerHTML={{ __html: content }} />

    {showBookingButton && (
      <Box sx={{ mb: 4 }}>
        <PrimaryLink className="nav-button" to="/chaloupky">
          Rezervovat
        </PrimaryLink>
      </Box>
    )}

    {gallery && <PostGallery images={gallery} />}
  </article>
);

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default BlogPostTemplate;
