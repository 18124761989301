import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Section } from '../components/Section';
import Container from '../components/Container';
import BlogPostTemplate from '../components/BlogPostTemplate';

const BlogPost = ({ data }) => {
  const { wordpressPost: post, site } = data;

  return (
    <Layout>
      <Helmet title={`${post.title} | ${site.siteMetadata.title}`} />
      <Section>
        <Container narrow>
          <BlogPostTemplate
            content={post.content}
            categories={post.categories}
            image={post.featured_media && post.featured_media.localFile.childImageSharp.fluid}
            imageAlt={post.featured_media && post.featured_media.alt_text}
            tags={post.tags}
            title={post.title}
            date={post.date}
            show_booking_button={post.acf.show_booking_button || false}
            gallery={post.acf.gallery || null}
          />
        </Container>
      </Section>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "DD.MM.YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      acf {
        show_booking_button
        gallery {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1024) {
                src
              }
              resize(width: 200, height: 200) {
                src
              }
            }
          }
        }
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 640, maxHeight: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      slug
      content
      date(formatString: "DD.MM.YYYY")
      tags {
        id
        name
        slug
        count
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
